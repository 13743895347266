/* eslint-disable import/extensions */
// eslint-disable import/extensions
import {
  cs,
  da,
  de,
  enUS,
  enGB,
  es,
  fr,
  it,
  ja,
  nb,
  nl,
  pl,
  pt,
  ptBR,
  zhCN,
  se
} from 'date-fns/locale'

const LOCALES_CONFIG = [
  {
    code: 'en',
    country: 'Global',
    language: 'English',
    iso: 'en',
    dateFormatter: enUS
  },
  {
    code: 'pt_BR',
    country: 'Brasil',
    language: 'Português',
    iso: 'pt-BR',
    dateFormatter: ptBR
  },
  {
    code: 'zh_CN',
    country: '中国',
    language: '简体中文',
    iso: 'zh-CN',
    dateFormatter: zhCN
  },
  {
    code: 'cs_CZ',
    country: 'Česká republika',
    language: 'Čeština',
    iso: 'cs-CZ',
    dateFormatter: cs
  },
  {
    code: 'da_DK',
    country: 'Danmark',
    language: 'Dansk',
    iso: 'da-DK',
    dateFormatter: da
  },
  {
    code: 'de_DE',
    country: 'Deutschland',
    language: 'Deutsch',
    iso: 'de-DE',
    dateFormatter: de
  },
  {
    code: 'es_ES',
    country: 'España',
    language: 'Español',
    iso: 'es-ES',
    dateFormatter: es
  },
  {
    code: 'fr_FR',
    country: 'France',
    language: 'Français',
    iso: 'fr-FR',
    dateFormatter: fr
  },
  {
    code: 'it_IT',
    country: 'Italia',
    language: 'Italiano',
    iso: 'it-IT',
    dateFormatter: it
  },
  {
    code: 'ja_JP',
    country: '日本',
    language: '日本語',
    iso: 'ja-JP',
    dateFormatter: ja
  },
  {
    code: 'es_MX',
    country: 'México',
    language: 'Español',
    iso: 'es-MX',
    dateFormatter: es
  },
  {
    code: 'nl_NL',
    country: 'Nederland',
    language: 'Nederlands',
    iso: 'nl-NL',
    dateFormatter: nl
  },
  {
    code: 'no_NO',
    country: 'Norge',
    language: 'Norsk',
    iso: 'no-NO',
    // NO is replaced by NB https://github.com/date-fns/date-fns/issues/2963#issuecomment-1041913677
    dateFormatter: nb
  },
  {
    code: 'pl_PL',
    country: 'Polska',
    language: 'Polski',
    iso: 'pl-PL',
    dateFormatter: pl
  },
  {
    code: 'pt_PT',
    country: 'Portugal',
    language: 'Português',
    iso: 'pt-PT',
    dateFormatter: pt
  },
  {
    code: 'sv_SE',
    country: 'Sverige',
    language: 'Svenska',
    iso: 'sv-SE',
    dateFormatter: se
  },
  {
    code: 'en_GB',
    country: 'United Kingdom',
    language: 'English',
    iso: 'en-GB',
    dateFormatter: enGB
  }
]

const EXCLUDED_LOCALES_TARGET_SITE = {
  careers: ['zh_CN', 'cs_CZ', 'es_MX', 'nl_NL', 'pl_PL', 'pt_PT', 'en_GB', 'en_AU', 'en_AE']
}

export const mergeLocales = (sources: any[]) => {
  const messages = sources.reduce((acc, source) => {
    Object.keys(source).forEach((key) => {
      acc[key] = { ...acc[key], ...source[key] }
    })
    return acc
  }, {})
  return messages
}

export const useLocales = (targetSite?: string, useDefaultLocaleOnly?: boolean) => {
  const TARGET_SITE = targetSite || 'main'
  /**
   * Locales configuration per target site
   * @property {Object[]} locales - list of available locales
   */
  const locales = useDefaultLocaleOnly
    ? [LOCALES_CONFIG[0]]
    : EXCLUDED_LOCALES_TARGET_SITE[TARGET_SITE]
      ? LOCALES_CONFIG.filter(({ code }) => !EXCLUDED_LOCALES_TARGET_SITE[TARGET_SITE].includes(code))
      : LOCALES_CONFIG

  /**
   * Default locale. If a locale doesn't exist, the app will fallback to this value
   * @property  {String} defaultLocale - language-region code
   */
  const defaultLocale = 'en'

  /**
   * Get locale in iso format from i18n locales
   * @param {String} code - locale code e.g nl_NL
   * @return {String} locale iso to be used in query variables e.g nl-NL
   */
  const getLocaleIso = (code) => {
    return locales.find(locale => locale.code === code)?.iso || 'en'
  }

  /**
   * Get locale's language from i18n locales
   * @param {String} code - locale code e.g nl_NL
   * @return {String} locale language to be used as copy
   */
  const getLocaleLanguage = (code) => {
    return locales.find(locale => locale.code === code)?.language || ''
  }

  /**
   * Get formatted locale from date-fns
   * @param {String} code - locale code e.g nl_NL
   * @return {Locale} i18n formatter locale language to be used in a formatter
   */
  const getI18nFormatterLocale = (code) => {
    return locales.find(locale => locale.code === code)?.dateFormatter
  }

  return {
    // messages,
    defaultLocale,
    locales,
    mergeLocales,
    getI18nFormatterLocale,
    getLocaleLanguage,
    getLocaleIso
  }
}
